// react/gatsby imports
import { navigate } from "gatsby";
import { useEffect } from "react";

// Раньше была страница /blog.
// 15.03.2024 перенесли блог на главную страницу
const BlogPage = () => {
  useEffect(() => {
    navigate("/#blog");
  }, []);
  return null;
};

export default BlogPage;
